import React, { useEffect, useState } from "react"
import _noop from "lodash/noop"
import { createGlobalStyle } from "styled-components"

import { useThirdPartiesContext } from "../../thirdParties/thirdParties.provider"
import { useLocation } from "../../navigation/location.provider"
import { useIsChatActive } from "../../website/useIsChatActive.hook"
import { loadChat } from "./purecloud"
import OverlayPicto from "../overlayPicto.component"
import ChatPicto from "../../../components/pictos/chat.component"
import { addEventTracking } from "../../thirdParties/gtm/tagManager"
import { useTranslation } from "../../translation/translate.component"
import { usePageContext } from "../../navigation/pageContext.provider"
import { localeToLang } from "../../translation/translate.utils"

const ChatStyle = createGlobalStyle`
  .cx-widget * {
    white-space: normal;
  }

  .cx-widget {
    font-family: ${props => props.theme.fonts.primary.family} !important;
    right: 76px !important;
  }
  
  .cx-widget .cx-webchat .cx-transcript .cx-message-group>.cx-message.cx-participant:first-child .cx-name {
    display: none !important;
  }

  .cx-titlebar .cx-icon {
    background-image: url("${APP_CONFIG.basePath}/icons/icon-48x48.png");
    background-size: 24px 24px;
    background-repeat: no-repeat;

    > svg {
      display: none;
    }
  }
`

const USER_ACTION = {
  NONE: 0,
  OPEN: 1,
  CLOSE: 2,
}

const CHAT_STATUS = {
  NONE: 0,
  OPENED: 1,
  CLOSED: 2,
}

function Chat () {
  const { t } = useTranslation()
  const { load, isLoaded } = useThirdPartiesContext()
  const location = useLocation()
  const { locale } = usePageContext()
  const isChatActive = useIsChatActive(location)
  const isChatScriptLoaded = isLoaded(`chat`)
  const [userAction, setUserAction] = useState(USER_ACTION.NONE)
  const [chat, setChat] = useState(() => ({
    ready: false,
    status: CHAT_STATUS.NONE,
    open: _noop,
    close: _noop,
    updateData: _noop,
  }))

  function setChatStatus (status) {
    setChat(prevState => ({ ...prevState, status }))
  }

  function onChatReady (openChat, closeChat) {
    setChat({
      ready: true,
      status: CHAT_STATUS.CLOSED,
      open () {
        openChat().catch(error => {
          console.error(`chat open failed`, error)
          setUserAction(USER_ACTION.NONE)
        })
      },
      close () {
        closeChat().catch(error => {
          console.error(`chat close failed`, error)
          setUserAction(USER_ACTION.NONE)
        })
      },
    })
  }

  function onChatOpen () {
    setChatStatus(CHAT_STATUS.OPENED)
    setUserAction(USER_ACTION.NONE)
  }

  function onChatClosed () {
    setChatStatus(CHAT_STATUS.CLOSED)
    setUserAction(USER_ACTION.NONE)
  }

  function onChatStarted () {
    eventTracking(`Conversation`, `demarrage`)
  }

  function eventTracking (action, label) {
    addEventTracking(`eventGA`, {
      eventCategory: `Chat`,
      eventAction: action,
      eventLabel: label,
    })
  }

  // Load script if chat is active
  useEffect(() => {
    if (isChatActive && !isChatScriptLoaded && userAction === USER_ACTION.OPEN) {
      load(`chat`)
    }
  }, [isChatActive, isChatScriptLoaded, userAction, load])

  // Load chat when script is ready
  useEffect(() => {
    if (isChatScriptLoaded) {
      const participantData = {
        site: APP_CONFIG.siteUrl,
        partnerCode: process.env.PARTNER,
        partnerFullName: APP_CONFIG.partnerFullName,
        lang: localeToLang(locale),
        locale,
      }

      loadChat(participantData, onChatReady, onChatOpen, onChatClosed, onChatStarted)
    }
  }, [isChatScriptLoaded])

  // Open/Close chat
  useEffect(() => {
    if (userAction === USER_ACTION.OPEN && chat.status === CHAT_STATUS.CLOSED) {
      chat.open()
      eventTracking(`Ouverture`, `ParVisiteur`)
    } else if (userAction === USER_ACTION.CLOSE && chat.status === CHAT_STATUS.OPENED) {
      chat.close()
    }
  }, [chat.status, userAction])

  useEffect(() => {
    if (isChatActive) {
      eventTracking(`Affichage`, ``)
    }
  }, [isChatActive])

  if (!isChatActive || chat.status === CHAT_STATUS.OPENED) {
    return null
  }

  return (
    <OverlayPicto
      data-testid="chat_trigger"
      onClick={() => setUserAction(USER_ACTION.OPEN)}
      tooltip={t(`common:chat.picto_tooltip`)}
    >
      <ChatStyle />
      <ChatPicto size={55} />
    </OverlayPicto>
  )
}

export default Chat
