// Each time `refresh` changed, we check if the chat should be active based on api schedules
import { graphql, useStaticQuery } from "gatsby"
import { useState, useEffect } from "react"
import { formatDatetime } from "../../utils/format"
import { useDateOrMockDate } from "../../utils/time"

export function useIsChatActive (refresh) {
  const [IsChatActive, setIsChatActive] = useState(false)
  const { websiteChatConfiguration } = useStaticQuery(graphql`
    query {
      websiteChatConfiguration {
        schedule {
          scheduleClosedDays {
            date
          }
          scheduleOpenDays {
            dayIndex
            scheduleHourRanges {
              endHour
              startHour
            }
          }
        }
      }
    }
  `)

  useEffect(() => {
    if (!websiteChatConfiguration) {
      return setIsChatActive(false)
    }

    const {
      schedule: { scheduleOpenDays, scheduleClosedDays },
    } = websiteChatConfiguration
    const now = new (useDateOrMockDate())()
    const nowDate = formatDatetime(now, `dd-mm-yyyy`)

    for (const scheduleClosedDay of scheduleClosedDays) {
      if (scheduleClosedDay.date === nowDate) {
        return setIsChatActive(false)
      }
    }

    const nowDayIndex = now.getDay()
    const nowTime = formatDatetime(now, `hh:ii:ss`)

    for (const scheduleOpenDay of scheduleOpenDays) {
      if (scheduleOpenDay.dayIndex !== nowDayIndex) {
        continue
      }

      for (const { startHour, endHour } of scheduleOpenDay.scheduleHourRanges) {
        if (nowTime >= startHour && nowTime < endHour) {
          return setIsChatActive(true)
        }
      }
    }

    setIsChatActive(false)
  }, [refresh])

  return IsChatActive
}
