const asyncCommand = command => (plugin, params) =>
  new Promise((resolve, reject) => {
    plugin
      .command(command, params)
      .done(resolve)
      .fail(reject)
  })

const openChat = asyncCommand(`WebChat.open`)
const closeChat = asyncCommand(`WebChat.close`)

export function loadChat (userData, onReady, onOpened, onClosed, onStarted) {
  window._genesys = window._genesys || {}

  window._genesys.widgets = {
    main: {
      mobileModeBreakpoint: APP_CONFIG.theme.breakpoints.small,
      downloadGoogleFont: false,
      theme: `light`,
      // TODO: When a language switch occurred, we need to change dynamically the lang
      lang: userData.lang,
      i18n: `${APP_CONFIG.baseUrl}/locales/${userData.locale}/purecloud-chat.json`,
    },
    webchat: {
      userData,
      async: {
        enabled: false,
      },
      transport: {
        type: `purecloud-v2-sockets`,
        dataURL: APP_CONFIG.chat.dataURL,
        deploymentKey: APP_CONFIG.chat.deploymentKey,
        orgGuid: APP_CONFIG.chat.orgGuid,
        interactionData: {
          routing: {
            targetType: `QUEUE`,
            targetAddress: APP_CONFIG.chat.targetAddress,
            priority: 2,
          },
        },
      },
    },
    onReady () {
      onReady(
        () =>
          openChat(webChatPlugin, {
            form: {
              autoSubmit: true,
            },
            formJSON: {
              wrapper: `<table></table>`,
              inputs: [],
            },
          }),
        () => closeChat(webChatPlugin),
      )
    },
  }

  const webChatPlugin = window.CXBus.registerPlugin(`WebChatPlugin`)

  // Subscribe events: https://all.docs.genesys.com/WID/Current/SDK/WebChat-combined
  webChatPlugin.subscribe(`WebChat.opened`, onOpened)
  webChatPlugin.subscribe(`WebChat.closed`, onClosed)
  webChatPlugin.subscribe(`WebChat.started`, onStarted)
}
