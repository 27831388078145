import React from "react"
import PropTypes from "prop-types"
import Picto from "../picto.component"

export default function Chat (props) {
  return (
    <Picto viewbox="0 0 330 331" {...props} color="#FFF">
      <path
        d="M252,161.7c0,11.3-2,22.5-6.1,33.3l10.2,66l-57.3-16.9c-11.8,4.6-24,7.7-36.3,7.7
c-49.6,0-90.1-40.4-90.1-90.1s40.4-89.5,90.1-89.5S252,112.1,252,161.7z M85.7,161.7c0,42.5,34.3,76.7,76.7,76.7
c12.8,0,23.5-3.1,35.3-8.7l42.5,12.8l-7.7-49.1c4.1-10.7,6.7-20.5,6.7-31.7c0-42.5-34.8-76.7-76.7-76.7
C120,85,85.7,119.3,85.7,161.7z"
      />
      <path d="M194.7,152.5c-5.1,0-9.2,4.1-9.2,9.2s4.1,9.7,9.2,9.7c5.6,0,9.7-4.6,9.7-9.7S200.3,152.5,194.7,152.5z" />
      <path d="M162.5,152.5c-5.1,0-9.7,4.1-9.7,9.2s4.6,9.7,9.7,9.7s9.2-4.6,9.2-9.7S167.6,152.5,162.5,152.5z" />
      <path d="M129.7,152.5c-5.1,0-9.2,4.1-9.2,9.2s4.1,9.7,9.2,9.7c5.1,0,9.2-4.6,9.2-9.7S134.8,152.5,129.7,152.5z" />
    </Picto>
  )
}

Chat.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
}
